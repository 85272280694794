import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7916a853"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-account" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "logo-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_FormSignIn = _resolveComponent("FormSignIn")!
  const _component_DecorativeBlock = _resolveComponent("DecorativeBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Logo, { size: _ctx.logoSize }, null, 8, ["size"])
      ]),
      _createVNode(_component_FormSignIn, {
        isAccount: true,
        title: _ctx.t('form.signIn'),
        textButton: _ctx.t('form.start')
      }, null, 8, ["title", "textButton"])
    ]),
    _createVNode(_component_DecorativeBlock)
  ]))
}