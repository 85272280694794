
  import { computed, defineComponent, ref, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { Button, Input, Notification, Popup, Typography } from '@voximplant/spaceui';
  import SignInAccount from '@/components/accounts/SignInAccount.vue';
  import GuestSignInForm from '@/components/forms/GuestSignInForm.vue';
  import { getRedirectUrl } from '@/helpers/googleAuth';
  import { useStore } from 'effector-vue/composition';
  import { authStore, AuthState, $isGuest, authorizeGuestSession, restoreAuth } from '@/store/auth';
  import { createMeeting } from '@/store/meeting';
  import { useRouter, useRoute } from 'vue-router';
  import { makeGuestAuth } from '@/store/auth';
  import Modal from '@/components/Modal.vue';
  import { openModal, closeModal } from '@/store/modal';
  import GuestSignInAccount from '@/components/GuestSignInAccount.vue';
  import { ErrorCodes } from '@/api/auth/ErrorCodes';
  import { stringifyError } from '@/helpers/stringifyError';
  import { delay } from '@/helpers/delay';

  export default defineComponent({
    name: 'FormSignIn',
    components: {
      Input,
      Button,
      SignInAccount,
      Notification,
      Modal,
      GuestSignInForm,
      GuestSignInAccount,
      Popup,
      Typography,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      alternative: {
        type: String,
        required: false,
      },
      textButton: {
        type: String,
        required: true,
      },
      isJoin: {
        type: Boolean,
        default: false,
        required: false,
      },
      isAccount: {
        type: Boolean,
        default: false,
        required: false,
      },
      authErrorCode: {
        type: Number,
        required: false,
      },
    },

    setup(props) {
      const router = useRouter();
      const conference = sessionStorage.getItem('backLink');
      const showGuestButton = ref(!!conference);
      const showGuestNameInput = ref(false);
      const getIconSize = function () {
        if (window.innerWidth < 768) return '24';
        else return '20';
      };
      const inProgress = ref(false);

      const googleButtonIcon = computed(() =>
        !inProgress.value
          ? { spriteUrl: '/image/videoconf-icons.svg', name: `ic${getIconSize()}-google` }
          : null
      );
      const makeGoogleAuth = () => {
        inProgress.value = true;
        location.href = getRedirectUrl();
      };
      restoreAuth.failData.watch(() => (inProgress.value = false));

      const toggleNameInput = () => (showGuestNameInput.value = !showGuestNameInput.value);

      const processGuestAuth = async (guestName: string) => {
        if (!guestName) return;
        inProgress.value = true;
        try {
          await makeGuestAuth(guestName);
          if (conference) {
            await router.replace({
              name: 'Join',
              params: {
                conference,
              },
            });
          }
        } finally {
          inProgress.value = false;
        }
      };

      const handleSignInExistingGuestSession = async (username: string) => {
        authorizeGuestSession(username);
        if (conference) {
          await router.replace({
            name: 'Join',
            params: {
              conference,
            },
          });
        }
      };

      const auth = useStore(authStore);
      const route = useRoute();
      const error = ref<string>(
        Number(route.params.authErrorCode) === ErrorCodes.VoxApplicationAuthFailed
          ? 'voxApplicationAuthError'
          : ''
      );

      // TODO: move join logic into effector
      const joinConf = async (e: Event) => {
        inProgress.value = true;
        e.preventDefault();
        try {
          const meeting = await createMeeting();
          if (meeting.meetingId) {
            await router
              .replace({
                name: 'Join',
                params: {
                  conference: meeting.meetingId,
                },
              })
              .catch(() =>
                console.error(
                  'FormSignIn component',
                  `joinConf router ERROR with meetingId ${meeting.meetingId}`
                )
              );
          }
        } catch (err) {
          console.error(`FormSign createMeeting error ${stringifyError(err)}`);
          if (err.status === 401) {
            await delay(1000);
            await joinConf(e);
          }
        } finally {
          inProgress.value = false;
        }
      };

      const { t } = useI18n();
      const authErrorModalContent = computed(() => ({
        title: t(`error.${error.value}.title`),
        description: t(`error.${error.value}.description`),
      }));

      const showErrorModal = (errorMsg: string) => {
        error.value = errorMsg;
        const unsubscribe = closeModal.watch(() => {
          error.value = '';
          unsubscribe();
        });
        openModal({
          opened: true,
        });
      };

      watch(
        error,
        (value) => {
          if (value) {
            showErrorModal(value);
          }
        },
        { immediate: true }
      );

      const isGuest = useStore($isGuest);

      const isSignInAvailable = computed(
        () => auth.value.authState !== AuthState.Processing || !inProgress.value
      );

      return {
        getIconSize,
        makeGoogleAuth,
        googleButtonIcon,
        auth,
        joinConf,
        inProgress,
        AuthState,
        toggleNameInput,
        showGuestButton,
        showGuestNameInput,
        processGuestAuth,
        error,
        closeModal,
        isGuest,
        handleSignInExistingGuestSession,
        authErrorModalContent,
        isSignInAvailable,
      };
    },
  });
