
  import { defineComponent, onMounted } from 'vue';
  import { getLink, getPin } from '@/helpers/link';
  import { Select, Input } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';
  import { closePopup } from '@/store/popup';

  export default defineComponent({
    components: {
      Select,
      Input,
    },
    data() {
      return {
        active: this.activeCountry,
      };
    },
    props: {
      title: {
        type: String,
      },
      number: {
        type: String,
      },
      countries: {
        type: Array,
      },
      activeCountry: {
        type: Object,
      },
    },
    setup() {
      const { t } = useI18n();
      const link = getLink();
      onMounted(() => {
        const source = document.getElementsByClassName('hint-container');
        if (source.length) source[0].addEventListener('click', () => setTimeout(closePopup, 600));
      });
      return {
        t,
        link,
        pin: getPin(),
      };
    },
  });
