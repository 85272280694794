import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3579fc12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "decorative-block" }
const _hoisted_2 = { class: "element" }
const _hoisted_3 = { class: "text-wrap" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.t('join.title')), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.t('join.description')), 1)
      ])
    ])
  ]))
}