
  import { defineComponent } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import { Typography } from '@voximplant/spaceui';
  import SignInAccount from '@/components/accounts/SignInAccount.vue';
  import { authStore } from '@/store/auth';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'GuestSignInAccount',
    components: {
      SignInAccount,
      Typography,
    },
    setup(props, { emit }) {
      const { t } = useI18n();
      const auth = useStore(authStore);
      const emitSignIn = () => emit('sign-in', auth.value.username);

      return {
        t,
        auth,
        emitSignIn,
      };
    },
  });
