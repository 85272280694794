import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-36395d9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sign-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotSupportedBrowser = _resolveComponent("NotSupportedBrowser")!
  const _component_FormAccount = _resolveComponent("FormAccount")!
  const _component_FormJoin = _resolveComponent("FormJoin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isNotSupportedBrowser)
      ? (_openBlock(), _createBlock(_component_NotSupportedBrowser, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.shouldShowAccountInfo)
            ? (_openBlock(), _createBlock(_component_FormAccount, { key: 0 }))
            : (_openBlock(), _createBlock(_component_FormJoin, { key: 1 }))
        ], 64))
  ]))
}