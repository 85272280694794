export enum ErrorCodes {
  /*
    An error occurs when the Videoconf Backend has failed to authorize user in a [application](https://voximplant.com/docs/gettingstarted/basicconcepts/applications).

    For example, the user is trying to authorize with email `john.doe@google.com`. The Videoconf Backend is trying to create user in an application with name `john.doe`.
    An error occurs in two cases: 
    - User with same username is already created in an application
    - User was created by the Videoconf Backend, but password of one was changed
  */
  VoxApplicationAuthFailed = 403,
}
