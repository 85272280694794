import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d921f7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-not-supported-browser" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "logo-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Notification = _resolveComponent("Notification")!
  const _component_DecorativeBlock = _resolveComponent("DecorativeBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Logo, { size: _ctx.logoSize }, null, 8, ["size"])
      ]),
      _createVNode(_component_Notification, { class: "not-supported-browser-notification" }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, {
            class: "error-icon",
            width: "38",
            height: "38",
            name: "ic24-error-fill",
            color: "--sui-red-500"
          }),
          _createVNode(_component_Typography, {
            class: "notification-title",
            fontSize: "16px",
            fontColor: "var(--sui-gray-900)",
            fontWeight: "500"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('notSupportedBrowser')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_DecorativeBlock)
  ]))
}