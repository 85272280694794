
  import Logo from '@/components/Logo.vue';
  import { defineComponent } from 'vue';
  import DecorativeBlock from '@/components/decorative-elements/DecorativeBlock.vue';
  import FormSignIn from '@/components/forms/FormSignIn.vue';
  import { useLogoSize } from '@/hooks/useLogoSize';
  import { Button } from '@voximplant/spaceui';
  import { useI18n } from 'vue-i18n';

  export default defineComponent({
    name: 'FormJoin',
    components: {
      FormSignIn,
      Button,
      Logo,
      DecorativeBlock,
    },

    setup() {
      const { t } = useI18n();
      const { logoSize } = useLogoSize();
      return {
        t,
        logoSize,
      };
    },
  });
