
  import { Notification, Icon, Typography } from '@voximplant/spaceui';
  import Logo from '@/components/Logo.vue';
  import DecorativeBlock from '@/components/decorative-elements/DecorativeBlock.vue';
  import { useI18n } from 'vue-i18n';
  import { useLogoSize } from '@/hooks/useLogoSize';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'NotSupportedBrowser',
    components: {
      Typography,
      Logo,
      DecorativeBlock,
      Notification,
      Icon,
    },
    setup() {
      const { t } = useI18n();
      const { logoSize } = useLogoSize();
      return {
        t,
        logoSize,
      };
    },
  });
