
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import Logo from '@/components/Logo.vue';
  import FormJoin from '@/components/layout-forms/FormJoin.vue';
  import FormAccount from '@/components/layout-forms/FormAccount.vue';
  import SettingsInDefault from '@/components/layout-settings/SettingsInDefault.vue';
  import NotSupportedBrowser from '@/components/layout-forms/NotSupportedBrowser.vue';
  import '@/store/chat/init';
  import { $isAuthorized, $isGuest, restoreAuth } from '@/store/auth';
  import { useMatchSupportedBrowser } from '@/hooks/useMatchSupportedBrowser';
  import { screenInnerHeight, useWindowInnerHeight } from '@/hooks/useWindowInnerHeight';

  const __default__ = defineComponent({
    name: 'SignIn',
    components: {
      Logo,
      FormJoin,
      FormAccount,
      SettingsInDefault,
      NotSupportedBrowser,
    },
    setup() {
      const isAuthorized = useStore($isAuthorized);
      const isGuest = useStore($isGuest);
      const shouldShowAccountInfo = computed(() => isAuthorized.value && !isGuest.value);
      restoreAuth();
      useWindowInnerHeight();
      const isNotSupportedBrowser = ref(false);
      onMounted(() => {
        isNotSupportedBrowser.value = useMatchSupportedBrowser();
      });
      return {
        isAuthorized,
        isNotSupportedBrowser,
        screenInnerHeight,
        shouldShowAccountInfo,
      };
    },
  });

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "15e225fb": (_ctx.screenInnerHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__