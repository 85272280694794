import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af909c12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "invite-people" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('invitePeople.description')), 1),
    _createVNode(_component_Input, {
      id: "copy",
      type: "copy",
      modelValue: _ctx.link
    }, null, 8, ["modelValue"])
  ]))
}