import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, renderSlot as _renderSlot, Teleport as _Teleport, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc653104"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "window" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.content.opened)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "hidden",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.content.component), _normalizeProps(_guardReactiveProps(_ctx.content.componentOptions)), null, 16)),
            _renderSlot(_ctx.$slots, "default", {
              initTab: _ctx.content.componentOptions?.initTab
            }, undefined, true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}