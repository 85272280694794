import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-be375588"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "guest-sign-in-account",
  tabindex: "0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_SignInAccount = _resolveComponent("SignInAccount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Typography, {
      class: "title",
      "font-type": "header",
      "font-size": "600"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t('form.signIn')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_SignInAccount, {
      class: "guest-account",
      userName: _ctx.auth.displayName,
      email: _ctx.auth.email,
      avatar: _ctx.auth.picture,
      onClick: _ctx.emitSignIn
    }, null, 8, ["userName", "email", "avatar", "onClick"])
  ]))
}