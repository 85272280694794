
  import { isModalOpened, closeModal } from '@/store/modal';
  import { defineComponent } from 'vue';
  import { useStore } from 'effector-vue/composition';
  import InvitePeople from './InvitePeople.vue';

  export default defineComponent({
    components: { InvitePeople },
    setup() {
      const content = useStore(isModalOpened);
      return {
        content,
        close: () => {
          closeModal();
        },
      };
    },
  });
