
  import { defineComponent, ref } from 'vue';
  import { Button, Input } from '@voximplant/spaceui';

  export default defineComponent({
    name: 'GuestSignInForm',
    components: {
      Button,
      Input,
    },
    emits: ['submit', 'close'],
    props: {
      inProgress: {
        type: Boolean,
        default: false,
      },
    },
    setup(_, { emit }) {
      const guestName = ref('');

      const emitSubmit = () => {
        if (!guestName.value) return;
        emit('submit', guestName.value);
      };

      const emitClose = () => {
        emit('close');
      };

      return {
        guestName,
        emitSubmit,
        emitClose,
      };
    },
  });
